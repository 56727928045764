import Cookies from 'js-cookie';
// const TokenKey = 'saber-access-token';
// const RefreshTokenKey = 'saber-refresh-token';
const TokenKey = 'saber-access-token-big';
const RefreshTokenKey = 'saber-refresh-token-big';
export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey);
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey);
}
