// let SiksoftUrl = 'https://saas.siksoft.com/api';//开发
let SiksoftUrl = 'https://my.siksoft.com/api';//线上
// let SiksoftUrl = '/api';
import request from '../axios'

export const loginByUsername = (tenantId, username, password, type, deptId, roleId, key, code) => request({
  url: SiksoftUrl + '/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    Authorization: "Basic dmlzdWFsOnZpc3VhbF9zZWNyZXQ=", //固定值
  },
  params: {
    tenantId,
    username,
    password,
    grant_type: "password",
    scope: "all",
    type
  }
});

export const refreshToken = (refresh_token, tenantId, deptId, roleId) => request({
  url: SiksoftUrl + '/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    Authorization: "Basic dmlzdWFsOnZpc3VhbF9zZWNyZXQ=", //固定值
    // 'Dept-Id': (website.switchMode ? deptId : ''),
    // 'Role-Id': (website.switchMode ? roleId : '')
  },
  params: {
    tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});