// import { Loading } from 'element-ui';
let flg = true;
import { refreshToken } from '@/api/user';
import { Message } from 'element-ui';
import { checkUrl } from '@/utils/utils';
import { getToken, setToken, getRefreshToken, setRefreshToken } from '@/utils/auth';
import axios from 'axios';
window.$glob = {
  url: '',
  params: {},
  query: {},
  header: {}
};
function getGlobParams() {
  var query = window.location.search.substring(1);
  query = query.split("&");
  query.forEach(ele => {
    var pair = ele.split("=");
    window.$glob.params[pair[0]] = pair[1]
  })
}
getGlobParams();
axios.defaults.timeout = 30000;
function statusname(status) {
  status = status.toString();
  let message = '';
  switch (status) {
    case "40030":
      message = "公用数据不可更改";
      break;
  }
  return message;
}
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
// let loadingInstance = '';
// axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  // loadingInstance = Loading.service({
  //   text: '拼命加载中',
  //   background: 'rgba(0,0,0,0)',
  //   spinner: 'el-icon-loading'
  // });
  let reg = /visual/;
  let url = config.url;
  if (reg.test(url)) {
    const meta = config.meta || {};
    const isToken = meta.isToken === false;
    config.headers['Authorization'] = "Basic dmlzdWFsOnZpc3VhbF9zZWNyZXQ=";
    // config.headers['tenant_id'] = '888888';
    // 让每个请求携带token
    if (getToken() && !isToken) {
      config.headers['Blade-Auth'] = 'bearer ' + getToken();
    }
  }
  if (!checkUrl(config.url)) config.url = window.$glob.url + config.url;
  let header = window.$glob.header || {};
  config.headers = Object.assign(config.headers, header);
  let data = window.$glob.query || {}
  let key;
  if (['get', 'delete'].includes(config.method)) {
    key = "params"
  } else if (['post', 'put'].includes(config.method)) {
    key = "data"
  }
  if (typeof (config[key]) === 'object') {
    config[key] = Object.assign(config[key] || {}, data)
  }
  if (config.headers.proxy) {
    let headers = {}
    for (let ele in config.headers) {
      if (typeof (config.headers[ele]) !== 'object') headers[ele] = config.headers[ele]
    }
    let form = {
      url: config.url,
      method: config.method,
      headers: headers
    }
    form[key] = config[key]
    config.url = window.$website.url + '/visual/proxy'
    config.method = 'post';
    config.data = form
  }
  return config
}, error => {
  return Promise.reject(error)
});
//HTTPrequest拦截
axios.interceptors.response.use(config => {
  if (config.data && (config.data.code >= 400 && config.data.code <= 500)) {
    // Message.error(statusname(config.data.msg));
    Message.error(config.data.msg);
  }
  if (config.data && config.data.msg == '10022') {
    let UserInfo = localStorage.getItem('saber-userInfo');
    let saberrefreshToken = localStorage.getItem('saberRefreshToken');
    if (UserInfo) {
      UserInfo = JSON.parse(UserInfo);
    }
    if (saberrefreshToken) {
      saberrefreshToken = JSON.parse(saberrefreshToken);
    }
    if (flg) {
      flg = false
      refreshToken(saberrefreshToken.content, UserInfo.tenant_id).then(res => {
        if (res.status == 200) {
          Message.error('授权失败,请重新刷新页面');
          setToken(res.data.access_token);
          setRefreshToken(res.data.refresh_token);
          let CurrentTime = Math.round(new Date());
          localStorage.setItem('saberAccessToken', JSON.stringify({
            datetime: CurrentTime,
            content: res.data.access_token
          }));
          localStorage.setItem('saber-userInfo', JSON.stringify(res.data));
        } else if (res.status == 400) {
          // window.open("https://saas.siksoft.com/");
        }
      }).catch(() => {

      });
      setTimeout(() => {
        flg = true;
      }, 5000);
    }
  }
  // loadingInstance.close();
  return config;
}, error => {
  // loadingInstance.close();
  return Promise.reject(new Error(error));
})

export default axios;
