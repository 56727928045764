<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { loginByUsername, refreshToken } from '@/api/user';
import md5 from 'js-md5';
import { getToken, setToken, getRefreshToken, setRefreshToken, removeToken, removeRefreshToken } from '@/utils/auth';
import { calcDate } from '@/utils/date';
import { validatenull } from '@/echart/util'
export default {
  name: 'app',
  data() {
    return {
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: '',
      //多少分钟后刷新token
      tokenTime: 50 * 60
    }
  },
  components: {
  },
  async created() {
    const userinfo = this.getArgs();
    if (userinfo.tenantId && userinfo.username && userinfo.password) {
      // ?tenantId=875540&username=18638331107&password=e10adc3949ba59abbe56e057f20f883e
      // ?tenantId=888888&username=13713769576&password=de1137efca4a38adf0230afef60f5744

      // ?tenantId=337425&username=18539556053&password=365cbc7db4f7e7056b1681981999c880
      // ?tenantId=000000&username=administrator&password=e2f43d8b87423d724204d6c981786182
      let loginForm = {
        //机构ID
        tenantId: userinfo.tenantId,
        //部门ID
        deptId: '',
        //角色ID
        roleId: '',
        //用户名
        username: userinfo.username,
        //密码
        password: userinfo.password,
        //账号类型
        type: 'account',
        //验证码的值
        code: '',
        //验证码的索引
        key: '',
      };
      // loginForm.password = md5(loginForm.password);
      await loginByUsername(loginForm.tenantId, loginForm.username, loginForm.password, loginForm.type).then(res => {
        if (res.status == 200) {
          this.FunSetToken(res.data);
          localStorage.setItem('saberRefreshToken', JSON.stringify({
            datetime: Math.round(new Date()),
            content: res.data.refresh_token
          }));
          this.$router.push({
            path: '/'
          })
        }
      })
    }
  },
  async mounted() {
    this.refreshToken();
  },
  methods: {
    //修改本地存的token数据
    FunSetToken(data) {
      setToken(data.access_token);
      setRefreshToken(data.refresh_token);
      let CurrentTime = Math.round(new Date());
      localStorage.setItem('saberAccessToken', JSON.stringify({
        datetime: CurrentTime,
        content: data.access_token
      }));
      localStorage.setItem('saber-userInfo', JSON.stringify(data));
    },
    //获取地址栏的参数
    getArgs() {
      var args = new Object();
      var query = window.location.search.substring(1);
      // var query = window.location.hash.substring(3);
      query = query.replace(/\+/g, " ");
      var pairs = query.split("&");
      for (var i = 0; i < pairs.length; i++) {
        var pos = pairs[i].indexOf('=');
        if (pos == -1) continue;
        var argname = pairs[i].substring(0, pos);
        var value = pairs[i].substring(pos + 1);
        value = decodeURIComponent(value);
        args[argname] = value;
      }
      return args;
    },
    // 每10秒调用一次该方法判断accesstoken的过期时间
    refreshToken() {
      this.refreshTime = setInterval(() => {
        let access_token = localStorage.getItem('saberAccessToken');
        let refresh_token = localStorage.getItem('saberRefreshToken');
        let UserInfo = localStorage.getItem('saber-userInfo');
        if (access_token) {
          access_token = JSON.parse(access_token);
        }
        if (refresh_token) {
          refresh_token = JSON.parse(refresh_token);
        }
        if (UserInfo) {
          UserInfo = JSON.parse(UserInfo);
        }
        const date = calcDate(access_token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= this.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          refreshToken(getRefreshToken(), UserInfo.tenant_id).then(res => {
            if (res.status == 200) {
              this.FunSetToken(res.data);
            } else if (res.status == 400) {
              removeToken();
              removeRefreshToken();
              localStorage.clear();
              Message.error('授权失败,请重新登录');
            } else if (res.status == 500 && res.data.error == 'server_error') {
              removeToken();
              removeRefreshToken();
              localStorage.clear();
              Message.error('授权失败,请重新登录');
            }
            this.refreshLock = false;
          }).catch(() => {
            this.refreshLock = false;
          });
        }
      }, 10000);
    },
  },
  destroyed() {
    removeToken();
    removeRefreshToken();
    localStorage.clear();
  }
}
</script>

<style>
</style>
